import { useState } from 'react';
import { Modal } from 'react-overlays';

import styles from './CookieModal.module.css';

type Props = {
    show: boolean;
};

export const CookieModal: React.FC<Props> = ({ show }) => {
    const [showModal, setShowModal] = useState<boolean>(show);

    const onCloseClick = () => {
        setShowModal(false);
    };

    return (
        <Modal className={styles.cookie_modal} show={showModal}>
            <div className={styles.container}>
                <h3 className={styles.title}>Cookie Notice</h3>
                <p>
                    By using this website you consent to the usage of functional cookies. More info at the bottom of the page.
                </p>
                <button className={styles.button} onClick={onCloseClick}>
                    Okay
                </button>
            </div>
        </Modal>
    );
};
