import { ReactNode } from 'react';
import styles from './SoftwareDownloadButton.module.css';

import { WindowsIcon } from '../../icons/WindowsIcon';
import { LinuxIcon } from '../../icons/LinuxIcon';
import { MacOsIcon } from '../../icons/MacOsIcon';
import { AndroidIcon } from '../../icons/AndroidIcon';
import { OperatingSystem } from '../../../../api';

type Props = {
    downloadUrl: string;
    softwareTitle: string;
    operatingSystem: OperatingSystem;
    version: string;
};

export const SoftwareDownloadButton: React.FC<Props> = ({
    downloadUrl,
    softwareTitle,
    operatingSystem,
    version,
}) => {
    const icons: Record<OperatingSystem, ReactNode> = {
        windows: <WindowsIcon />,
        linux: <LinuxIcon />,
        apple: <MacOsIcon />,
        android: <AndroidIcon />,
    };

    return (
        <a href={downloadUrl} className={styles.link}>
            <div className={styles.container}>
                <div>
                    <h3 className={styles.title}>{softwareTitle}</h3>
                </div>
                <div className={styles.info_container}>
                    {icons[operatingSystem]} | {version}
                </div>
            </div>
        </a>
    );
};
