import { Article } from '../../Article/Article';
import { Page } from '../../Page/Page';

import banner from '../../../resources/images/banner.jpg';
import { LogoContainer } from '../../LogoContainer/LogoContainer';

import styles from './Home.module.css';
import { Divider } from '../../Divider/Divider';

export const Home: React.FC = () => {
    return (
        <Page>
            <div
                className={styles.bannerContainer}
                style={{
                    backgroundImage: `url(${banner})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}
            >
                <div className={styles.bannerTextContainer}>
                    <h1>Hi, I'm David!</h1>
                </div>
            </div>
            <div className={styles.textContainer}>
                <p>
                    I am a former police officer who now builds software for a
                    living. In my day job, I work as a frontend engineer and
                    build industrial software for a global player based in Germany.
                </p>
                <p>
                    During my free time, I like to work on lots of different
                    projects (e.g. this website, games, apps and whatever sparks
                    my interest). Take a peek at my released projects under
                    "software"!
                </p>
                <p>
                    Here are some of the technologies I have looked into so far:
                </p>
            </div>
            <div className={styles.technologyContainer}>
                <LogoContainer url="https://react.dev">
                    <svg
                        viewBox="-10.5 -9.45 21 18.9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="0"
                            cy="0"
                            r="2"
                            fill="currentColor"
                        ></circle>
                        <g stroke="currentColor" stroke-width="1" fill="none">
                            <ellipse rx="10" ry="4.5"></ellipse>
                            <ellipse
                                rx="10"
                                ry="4.5"
                                transform="rotate(60)"
                            ></ellipse>
                            <ellipse
                                rx="10"
                                ry="4.5"
                                transform="rotate(120)"
                            ></ellipse>
                        </g>
                    </svg>
                </LogoContainer>
                <LogoContainer url="https://unity3d.com">
                    <svg
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_5843_594)">
                            <path
                                d="M7.73279 2.86258L10.276 4.3245C10.3675 4.37583 10.3708 4.51821 10.276 4.56954L7.25407 6.30795C7.16264 6.36093 7.0546 6.35762 6.96982 6.30795L3.94785 4.56954C3.85476 4.51987 3.8531 4.37417 3.94785 4.3245L6.48943 2.86258V0L0 3.73179V11.1954L2.48839 9.7649V6.84106C2.48673 6.73676 2.60973 6.66225 2.70116 6.71854L5.72313 8.45695C5.81456 8.50993 5.86609 8.60431 5.86609 8.70199V12.1772C5.86775 12.2815 5.74474 12.356 5.65332 12.2997L3.11007 10.8377L0.621682 12.2682L7.11111 16L13.6005 12.2682L11.1122 10.8377L8.56891 12.2997C8.47915 12.3543 8.35281 12.2831 8.35614 12.1772V8.70199C8.35614 8.59768 8.41432 8.50497 8.49909 8.45695L11.5211 6.71854C11.6108 6.66391 11.7372 6.73344 11.7338 6.84106V9.7649L14.2222 11.1954V3.73179L7.73279 0V2.86258Z"
                                fill="white"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_5843_594">
                                <rect
                                    width="14.2222"
                                    height="16"
                                    fill="white"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </LogoContainer>
                <LogoContainer url="https://unrealengine.com">
                    <img
                        src="https://cdn.worldvectorlogo.com/logos/unreal-1.svg"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer url="https://www.typescriptlang.org/">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/1200px-Typescript_logo_2020.svg.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1820px-Logo_C_sharp.svg.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer url="https://java.com">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/de/thumb/e/e1/Java-Logo.svg/1200px-Java-Logo.svg.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer url="https://git-scm.com/">
                    <img
                        src="https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1200px-ISO_C%2B%2B_Logo.svg.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer url="https://www.electronjs.org/">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Electron_Software_Framework_Logo.svg/1200px-Electron_Software_Framework_Logo.svg.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
                <LogoContainer url="https://arduino.cc">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Arduino_IDE_logo.svg/2048px-Arduino_IDE_logo.svg.png"
                        style={{ width: '100%' }}
                    />
                </LogoContainer>
            </div>
        </Page>
    );
};
