import styles from './Software.module.css';
import overdrive from '../../../resources/images/overdrive.png';
import bliblogo from '../../../resources/images/blib.png';
import { Page } from '../../Page/Page';
import { Article } from '../../Article/Article';
import { useEffect, useState } from 'react';
import {
    OperatingSystem,
    SoftwaresList,
    SoftwareVersion,
} from '../../../../../api';
import { Divider } from '../../Divider/Divider';
import { SoftwareDownloadButton } from '../../SoftwareTable/SoftwareDownloadButton';

export const Software: React.FC = () => {
    const [softwares, setSoftwares] = useState<SoftwaresList>({
        overdrive: [],
        blib: [],
    });

    useEffect(() => {
        fetch('/software', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then(
                (json) => setSoftwares(json),
                (error) => {
                    console.error(error);
                },
            );
    }, []);

    const renderDownloadButtons = (files: SoftwareVersion[]) => {
        return files.map((item) => {
            const splitUrl = item.url.split('/');
            const filename = splitUrl[splitUrl.length - 1];
            const version = filename.replace('.zip', '').replace('.apk', '').split('_')[1];

            return (
                <SoftwareDownloadButton
                    downloadUrl={item.url}
                    softwareTitle={filename.split('_')[0]}
                    operatingSystem={item.platform as OperatingSystem}
                    version={version}
                />
            );
        });
    };

    return (
        <Page>
            <Article title="OVERDRIVE">
                <img src={overdrive} className={styles.banner} />
                <p>
                    … is a music app that I created out of the necessity to have
                    a specialized software for playing goal jingles at sport
                    events. As a speaker I could not find a music player that
                    fit my needs, so I built my own with focus on track
                    organization and ease-of-use.
                </p>
                <p>Main features include:</p>
                <ul>
                    <li>
                        touch-optimized interface for quickly starting goal
                        jingles
                    </li>
                    <li>display multiple playlists next to each other</li>
                    <li>
                        single play and auto play for each playlist individually
                    </li>
                </ul>
                <p>
                    The software is built with Electron, React and TypeScript
                    and currently available for Windows. You can download the
                    latest version here:
                </p>
                <div className={styles.download_container}>
                    {renderDownloadButtons(softwares?.overdrive)}
                </div>
            </Article>
            <Divider />
            <Article title="BliB">
                <img src={bliblogo} className={styles.banner} />
                <p>
                    ... is a small arcade game that tests your reaction and
                    memory at the same time. Switch your central BliB from black
                    to white by tapping the screen and match it with the
                    incoming smaller BliBs. But be careful - you can only see
                    one color of BliBs at the same time!
                </p>
                <p>
                    BliB is built with the Unity3D Game Engine and C# and was
                    released to the Google Play Store. Since I dropped the
                    support, it is no longer available there. But you can still
                    download the .apk file here, if you are interested:
                </p>
                <div className={styles.download_container}>
                    {renderDownloadButtons(softwares?.blib)}
                </div>
            </Article>
        </Page>
    );
};
